import * as Sentry from "@sentry/react";
import RouteError from "@/components/error-displays/UnknownError/RouteError";
import { CONFIG } from "@/config";
import ApproveAzureAD from "@/pages/auth/ApproveAzureAD";
import createAskUserDataRoute from "@/pages/auth/ask-user-data/createRoute";
import { createRoute as createLogInRoute } from "@/pages/auth/login/createRoute";
import createLogOutRoute from "@/pages/auth/logout/createRoute";
import createResetPasswordRoute from "@/pages/auth/reset-password/createRoute";
import ShowIdToken from "@/pages/auth/ShowIDToken";
import createSignUpRoute from "@/pages/auth/sign-up/createRoute";
import TestCustomToken from "@/pages/auth/TestCustomToken";
import createVerifyEmailRoute from "@/pages/auth/verify-email/createRoute";
import LaunchAuth from "@/pages/LaunchAuth";
import { ALL_PATIENTS_PATH } from "@/pages/patients/const";
import createPatientRoutes from "@/pages/patients/createRoute";
import ReportAnalytics from "@/pages/ReportAnalytics/ReportAnalytics";
import createSettingsRoute from "@/pages/settings/createRoute";
import createSMARTRoutes from "@/pages/smart/createRoute";
import { createRoute as createTableExportRoute } from "@/pages/table-export/createRoute";
import { queryClient } from "@/services/query";
import { createBrowserRouter, redirect, RouteObject } from "react-router-dom";
import DebugRoute from "./DebugRoute";
import MainLayout from "./MainLayout";
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

type MyRoute = RouteObject & { test?: string };
export const ROUTES: MyRoute[] = [
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <RouteError />,
    children: [
      {
        index: true,
        loader: () => redirect(ALL_PATIENTS_PATH),
        element: <DebugRoute />,
      },
      /** LEGACY ROUTES */
      {
        path: "/notes",
        loader: async ({ request }) => {
          console.log("Redirecting to /patients/dispatch");
          const url = new URL(request.url);
          url.pathname = "/patients/dispatch";
          return redirect(url.toString());
        },
        Component: () => {
          return <div>Redirecting to /patients/dispatch</div>;
        },
      },
      /** END LEGACY ROUTES */
      {
        path: "approve-azure-ad",
        element: <ApproveAzureAD />,
      },
      createTableExportRoute(queryClient),
      {
        path: "get-id-token",
        element: <ShowIdToken />,
      },

      {
        path: "test-custom-token",
        element: <TestCustomToken />,
      },
      createLogInRoute(queryClient),
      createResetPasswordRoute(),
      createVerifyEmailRoute(),
      createSettingsRoute(queryClient),
      createAskUserDataRoute(queryClient),
      createSMARTRoutes(),
      createLogOutRoute(queryClient),
      createPatientRoutes(queryClient),
      createSignUpRoute(),
      { path: "dashboard", element: <ReportAnalytics /> },
      {
        path: "*",
        loader: () => (CONFIG.isDev ? null : redirect(ALL_PATIENTS_PATH)),
        element: <DebugRoute />,
      },
      {
        path: "launch",
        element: <LaunchAuth />,
      },
    ],
  },
];
export const Router = sentryCreateBrowserRouter(ROUTES, {});

import { useEffect, useState } from "react";
import { useLocation, useRouteError } from "react-router";
import * as Sentry from "@sentry/react";
import UnknownError from "@/components/error-displays/UnknownError/UnknownError";

export default function RouteError() {
  const location = useLocation();
  const [isErrorCaptured, setIsErrorCaptured] = useState<
    false | "submitting" | true
  >(false);
  const error = useRouteError() as Error;
  // sync error to APM
  useEffect(() => {
    try {
      setIsErrorCaptured("submitting");
      Sentry.captureException(error);
      setIsErrorCaptured(true);
    } catch (e) {
      setIsErrorCaptured(false);
      console.error("Failed to sync error to APM", e);
    }
  }, [error]);
  const errorMessage =
    error?.message ??
    "Foutmelding:\n" +
      error?.message +
      "\n\nExtra informatie:\n" +
      error?.stack +
      "\n\n";
  return (
    <UnknownError
      title="Er ging iets fout..."
      description={
        <section className="prose">
          <p>
            Bij het ophalen van je data ging er iets fout. Onze excuses voor dit
            ongemak.
          </p>
          <h6 className="mt-1 text-base font-medium leading-normal">
            Wat kan je zelf doen?
          </h6>
          <p className="mt-0.5">
            Ga terug naar vorige pagina of contacteer de helpdesk.
          </p>
          {isErrorCaptured && (
            <p className="mt-1">
              Wij hebben dit probleem reeds geregistreerd en zullen dit zo snel
              mogelijk oplossen.
            </p>
          )}
        </section>
      }
      mailTo={{
        label: "Contacteer de Tiro.health helpdesk.",
        email: "admin@tiro.health",
        subject: "Fout bij ophalen van data",
        body:
          "Beste helpdesk,\n\nIk heb een foutmelding gekregen bij het ophalen van mijn data.\n\n" +
          (error.message
            ? "Tecnhische foutmelding:\n" +
              error?.message +
              "\n\nExtra informatie:\n" +
              error?.stack +
              "\n\n"
            : "") +
          "Ik heb deze foutmelding gekregen op de volgende pagina: " +
          location.pathname +
          " op " +
          new Date().toLocaleString() +
          "\n\n" +
          "Met vriendelijke groeten,\n",
      }}
    >
      <details className="mt-4">
        <summary className="mt-2 text-base text-gray-dark">
          Technische details foutmelding
        </summary>
        <dl className="mt-1">
          <dt className="text-sm font-medium">Foutmelding:</dt>
          <dd>
            <code className="mt-1 px-1 text-xs">{errorMessage}</code>
          </dd>
          <dt className="sr-only">Extra informatie:</dt>
          <dd>
            <pre className="mt-2 max-h-72 overflow-y-scroll text-sm">
              {error?.stack}
            </pre>
          </dd>
        </dl>
      </details>
    </UnknownError>
  );
}

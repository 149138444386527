import ReportsOverview from "../ReportsOverview";
import { PageContainer } from "@/components/layout/PageContainer";
import PickATemplateForm from "./PickATemplateForm";
import SubjectBadge from "@/components/controlled/SubjectBadge";
import useCurrentUserData from "@/services/reports/useUserData";
import { Spinner } from "@/Icons";
import { useTranslation } from "react-i18next";

const NewReportPage = () => {
  const { t } = useTranslation();
  const { data } = useCurrentUserData();
  return (
    <PageContainer className="min-h-full bg-fill-secondary">
      <div className="flex h-full w-full flex-col items-stretch">
        <div>
          <div className="flex justify-between font-primary">
            <div>
              <h2 className="text-2xl font-semibold text-content-primary">
                {t("maak-een-nieuw-verslag")}
              </h2>
              <p className="mt-1 text-base text-content-primary">
                {t("kies-een-template-om-snel-je-verslag-te-bouwen")}
              </p>
            </div>
            <div className="flex flex-col items-end">
              <SubjectBadge className="bg-white" />
            </div>
          </div>
          {data ? (
            <PickATemplateForm specialty={data.speciality} />
          ) : (
            <Spinner className="h-8 w-8 animate-spin" />
          )}
        </div>
        <ReportsOverview className="mt-8" />
      </div>
    </PageContainer>
  );
};
export default NewReportPage;

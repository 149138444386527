import { is, string } from "superstruct";
import client, {
  DefinedValueSet,
  ExpandableValueSetDoc,
  ExpansionContains,
} from "./client";
import * as Sentry from "@sentry/react";
import keyFactory from "./keyFactory";

class ValueSetExpansionError extends Error {
  url?: string;
  query?: string;

  constructor(message: string, url?: string, query?: string) {
    super(message);
    this.name = "ValueSetExpansionError";
    this.url = url;
    this.query = query;
  }

  toString() {
    return this.name + ": " + this.message;
  }
}
type ValueSetExpansionOptions = {
  filter?: string;
  offset?: number;
  fuzzy?: boolean;
  prefix?: boolean;
  acronym?: boolean;
  count?: number;
  displayLanguage?: string;
};
export const createValueSetExpansionQuery = (
  urlOrValueSet: string | DefinedValueSet,
  options?: ValueSetExpansionOptions,
) => {
  return {
    queryKey: keyFactory.expandValueSet(urlOrValueSet, options),
    queryFn: () => {
      return client.r5.expandValuesetWithBodyR5({
        url: is(urlOrValueSet, string()) ? urlOrValueSet : undefined,
        requestBody: is(urlOrValueSet, string()) ? undefined : urlOrValueSet,
        ...options,
      });
    },
    keepPreviousData: true,
    staleTime: 1000 * 60, // 1 minute
    select: (data: ExpandableValueSetDoc) => data.expansion?.contains ?? [],
    onError(error: unknown) {
      console.log(error);
      let message = "Failed to expand value set";
      if (error instanceof Error) {
        message += ": " + error.message;
      } else if (typeof error === "string") {
        message += ": " + error;
      }
      const vsExpansionErrror = new ValueSetExpansionError(
        message,
        is(urlOrValueSet, string()) ? urlOrValueSet : urlOrValueSet?.url,
        options?.filter,
      );
      Sentry.captureException(vsExpansionErrror);
    },
  };
};

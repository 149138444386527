import parse from "date-fns/parse";
import * as Sentry from "@sentry/react";
type Options = {
  locale?: string;
  errorOnInvalid?: boolean;
};

export const DATE_PRINT_MAP = {
  date: (value: string, options?: Options) => {
    const { locale, errorOnInvalid = true } = options || {};
    const dateFormatter = new Intl.DateTimeFormat(locale);
    try {
      return dateFormatter.format(parse(value, "yyyy-MM-dd", new Date()));
    } catch (e) {
      if (errorOnInvalid) throw e;
      console.error(e);
      if (e instanceof Error) {
        Sentry.captureException(e);
      }
      return null;
    }
  },
  month: (value: string, options?: Options) => {
    const { locale, errorOnInvalid = true } = options || {};
    const dateFormatter = new Intl.DateTimeFormat(locale, {
      month: "long",
      year: "numeric",
    });
    try {
      return dateFormatter.format(parse(value, "yyyy-MM", new Date()));
    } catch (e) {
      if (errorOnInvalid) throw e;
      console.error(e);
      if (e instanceof Error) {
        apm.captureError(e);
      }
      return null;
    }
  },
  time: (value: string, options?: Options) => {
    const { locale, errorOnInvalid = true } = options || {};
    const dateFormatter = new Intl.DateTimeFormat(locale, {
      timeStyle: "short",
    });
    try {
      return dateFormatter.format(parse(value, "HH:mm", new Date()));
    } catch (e) {
      if (errorOnInvalid) throw e;
      console.error(e);
      if (e instanceof Error) {
        apm.captureError(e);
      }
      return null;
    }
  },
  "datetime-local": (value: string, options?: Options) => {
    const { locale, errorOnInvalid = true } = options || {};
    const dateFormatter = new Intl.DateTimeFormat(locale, {
      dateStyle: "short",
      timeStyle: "short",
    });
    try {
      return dateFormatter.format(
        parse(value, "yyyy-MM-dd'T'HH:mm", new Date()),
      );
    } catch (e) {
      if (errorOnInvalid) throw e;
      console.error(e);
      if (e instanceof Error) {
        apm.captureError(e);
      }
      return null;
    }
  },
};

import { saveAllAsync } from "@/hooks/useSave";
import client from "@/services/reports/client";
import { ActionFunction } from "@remix-run/router";
import { QueryClient } from "react-query";
import { z } from "zod";

export default (queryClient: QueryClient): ActionFunction =>
  async ({ params }) => {
    const reportId = z.coerce.number().parse(params.reportId, {
      errorMap: () => {
        return { message: "Report ID is not a number" };
      },
    });
    await saveAllAsync();
    await client.v1.submitReportV1({ reportId });
    return {};
  };

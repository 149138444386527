import { CONFIG } from "@/config";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import { Router } from "@/routing/Routes";
import { QueryClientProvider } from "react-query";
import {
  RouterProvider,
  createRoutesFromChildren,
  useLocation,
  useNavigationType,
  matchRoutes,
} from "react-router-dom";
import { queryClient } from "@/services/query";
import { ReactQueryDevtools } from "react-query/devtools";
import InitPostHog from "@/components/InitPostHog";
import InitAPM from "@/components/InitAPM";
import React from "react";

Sentry.init({
  dsn: "https://10a8aca55105754e3d3b595700ff99e2@o4507651309043712.ingest.de.sentry.io/4508806227558480",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      autoInject: false, //
      colorScheme: "system",
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/(reports|content|templates|terminology).tiro.health/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const PH_OPTIONS = {
  api_host: import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

declare global {
  interface Window {
    chrome?: {
      webview: {
        postMessage: (message: Object) => Promise<void>;
        addEventListener: (
          event: "message",
          callback: (arg: MessageEvent) => void,
        ) => void;
        removeEventListener: (
          event: "message",
          callback: (arg: MessageEvent) => void,
        ) => void;
        hostObjects: {
          bridge: Promise<{
            setFHIRDocument: (params: string) => Promise<void>;
          }>;
        };
      };
    };
  }
}

const App = () => {
  return (
    <PostHogProvider
      options={{
        ...PH_OPTIONS,
        disable_session_recording:
          !CONFIG.isDemo && !CONFIG.isStaging && !CONFIG.isDev,
      }}
      apiKey={import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    >
      <QueryClientProvider client={queryClient}>
        <InitPostHog />
        <InitAPM />
        <RouterProvider router={Router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PostHogProvider>
  );
};

export default App;

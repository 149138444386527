import { PageContainer } from "@/components/layout/PageContainer";
import { useState } from "react";
import NewPatientModal from "./NewPatientModal";
import PatientsTable from "./PatientsTable";

import PatientTableContainer from "./PatientTableContainer";

function PatientsOverView() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PageContainer className="min-h-full bg-fill-secondary">
      <NewPatientModal open={isOpen} close={() => setIsOpen(false)} />
      <PatientTableContainer
        className="h-full"
        onAddNewPatient={() => setIsOpen(true)}
      >
        <PatientsTable className="relative max-h-full overflow-hidden rounded-md border border-blue-ultralight bg-white shadow-sm" />
      </PatientTableContainer>
    </PageContainer>
  );
}

export default PatientsOverView;

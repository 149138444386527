import React, { HTMLAttributes } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { SPECIALTIES } from "./SPECIALTIES";
export const SPECIALTY_TO_PATHWAYS: Record<
  (typeof SPECIALTIES)[number],
  string[]
> = {
  "HippoDX - ABL study": ["hippo-abl"],
  "HippoDX - AZH study": ["hippo-azh"],
  Urologie: ["raised-psa", "prostate-cancer", "cystectomie"],
  "Algemene heelkunde": [
    "ileo-ceacal-resection",
    "vascular-surgery",
    "aortic-valve-implantation",
  ],
  Heelkunde: ["aortic-valve-implantation"],
  "Abdominale heelkunde": [
    "ileo-ceacal-resection",
    "rectum-cancer",
    "ventral-mesh-repair",
  ],
  Pneumologie: ["MDTRespiratoryOncology", "lung-tx"],
  "Oncologische pneumologie": ["MDTRespiratoryOncology"],
  "Radiotherapie-oncologie": ["MDTRespiratoryOncology"],
  Vaatheelkunde: ["vascular-surgery"],
  Hematologie: ["hematology"],
  Cardiologie: [
    "heart-failure",
    "interventional-cardiology",
    "aortic-valve-implantation",
  ],
  FRAME: ["frame"],
  Neurologie: ["headache", "migraine", "neurology"],
  Radiologie: ["mri-knee", "rad-mri-prostate", "rad-echo-schildklier"],
  "InterSystems Respiratory Oncology": ["intersystems-respiratory-oncology"],
  Ergotherapie: ["occupational-therapy"],
  "Synops Dev": ["synops-dev", "azmm-cardiologie-synops-test"],
  Andere: ["all"],
  "Gynaecologie-verloskunde": ["borstcarcinoma", "gynaecology"],
  "Pathologische anatomie": ["biopsy"],
  "Synops Applicatiebeheer": ["azmm"],
  "Abdominale Heelkunde (Ventral Mesh Repair)": ["ventral-mesh-repair"],
};
function SpecialismListBoxInput({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const options = SPECIALTIES;
  return (
    <div className={classNames("relative mt-1", className)} {...props}>
      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-light bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
        <span className="block truncate">{children}</span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>

      <Transition
        as={React.Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map((specialty) => (
            <Listbox.Option
              key={specialty}
              className={({ active }) =>
                classNames(
                  active ? "bg-blue-primary text-white" : "text-gray-900",
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                )
              }
              value={specialty}
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={classNames(
                      selected ? "font-semibold" : "font-normal",
                      "block truncate",
                    )}
                  >
                    {specialty}
                  </span>
                  {selected ? (
                    <span
                      className={classNames(
                        active ? "text-white" : "text-blue-primary",
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </div>
  );
}

const SpecialismLabel = ({
  className,
  ...props
}: HTMLAttributes<HTMLLabelElement>) => (
  <Listbox.Label
    className={classNames(
      "block text-sm font-medium text-gray-dark",
      className,
    )}
    {...props}
  />
);

export interface SpecialismListBoxProps extends HTMLAttributes<HTMLDivElement> {
  name?: string;
  specialism?: (typeof SPECIALTIES)[number];
  onSpecialismChange: (specialism: (typeof SPECIALTIES)[number]) => void;
}

function SpecialismListBox({
  name,
  specialism,
  onSpecialismChange,
  children,
}: React.PropsWithChildren<SpecialismListBoxProps>) {
  return (
    <Listbox
      as="div"
      name={name}
      value={specialism}
      onChange={onSpecialismChange}
    >
      {children}
    </Listbox>
  );
}

SpecialismListBox.Input = SpecialismListBoxInput;
SpecialismListBox.Label = SpecialismLabel;

export default SpecialismListBox;

import { IfhirR4 } from "@smile-cdr/fhirts";
import { useQuery, UseQueryOptions } from "react-query";
import { assert, integer } from "superstruct";
import client from "./client";
import keyFactory from "./keyFactory";

type FHIRDocumentViewOptions = {
  includeExtractedResources?: boolean;
};

export function createFHIRDocumentViewQuery(
  reportId: number,
  options?: FHIRDocumentViewOptions,
) {
  return {
    queryKey: keyFactory.fhirDocumentView(reportId, {
      includeExtractedResources: options?.includeExtractedResources,
    }),
    queryFn: async () => {
      assert(reportId, integer());
      const result = client.v1.getFhirDocumentV1({
        reportId,
        extractResources: options?.includeExtractedResources,
      });
      return result;
    },
  };
}
type UseFHIRDocumentViewOptions = UseQueryOptions<IfhirR4.IBundle> &
  FHIRDocumentViewOptions;
function useFHIRDocumentView(
  reportId: number,
  options: UseFHIRDocumentViewOptions = {},
) {
  const { includeExtractedResources, ...otherOptions } = options;
  return useQuery<IfhirR4.IBundle>({
    ...createFHIRDocumentViewQuery(reportId, { includeExtractedResources }),
    ...otherOptions,
    enabled: options?.enabled && !!reportId,
  });
}

export default useFHIRDocumentView;

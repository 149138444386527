import { UserCredential } from "firebase/auth";
import * as Sentry from "@sentry/react";
import { ASK_USER_DATA_PATH } from "@/pages/auth/ask-user-data/const";
import { LOGIN_PATH } from "@/pages/auth/login/const";
import { VERIFY_EMAIL_PATH } from "@/pages/auth/verify-email/const";
import { LOGOUT_PATH } from "@/pages/auth/logout/const";
import { QueryClient } from "react-query";
import { LoaderFunctionArgs, redirect } from "react-router";
import keyFactory from "@/services/auth/keyFactory";
import { loginWithIdToken } from "@/services/auth/login";
import {
  hasVerifiedEmailQuery,
  HasVerifiedEmailQuery,
} from "@/services/auth/useHasVerifiedEmail";
import {
  isAuthenticatedQuery,
  IsAuthenticatedQuery,
} from "@/services/auth/useIsAuthenticated";
import {
  currentUserDataOrFalseQuery,
  CurrentUserDataOrFalseQuery,
} from "@/services/reports/useUserData";
import isUserDataComplete from "@/pages/auth/isUserDataComplete";
import { jumpToAuthFlow } from "./auth-flow";
import { hasSessionQuery, HasSessionQuery } from "@/services/auth/useSession";
export type CreateProtectorConfig = {
  queryClient: QueryClient;
  isAuthenticatedQuery: IsAuthenticatedQuery;
  hasVerifiedEmailQuery: HasVerifiedEmailQuery;
  hasSessionQuery: HasSessionQuery;
  currentUserDataOrFalseQuery: CurrentUserDataOrFalseQuery;
  signInWithCustomTokenFn: (token: string) => Promise<UserCredential>;
};

export const PROTECTOR_CONFIG_DEFAULTS = {
  isAuthenticatedQuery,
  hasVerifiedEmailQuery,
  hasSessionQuery,
  currentUserDataOrFalseQuery,
  signInWithCustomTokenFn: loginWithIdToken,
};
export type ProtectorReturnType = Response | null;
export default (config: CreateProtectorConfig) =>
  async ({ request }: LoaderFunctionArgs): Promise<ProtectorReturnType> => {
    const {
      queryClient,
      isAuthenticatedQuery,
      hasSessionQuery,
      hasVerifiedEmailQuery,
      currentUserDataOrFalseQuery,
      signInWithCustomTokenFn,
    } = config;
    const url = new URL(request.url);
    const searchParams = url.searchParams;

    // Extract the unsuafe user identifier from the query params
    // If it exists, store it in session storage
    // If it exists and is different from the one in session storage,
    // do a logout as precaution
    const userId = searchParams.get("user");
    const existingUnsafeUserId = sessionStorage.getItem("unsafeUserId");
    if (userId) {
      sessionStorage.setItem("unsafeUserId", userId);
      searchParams.delete("user");
    }
    if (existingUnsafeUserId && userId && existingUnsafeUserId !== userId) {
      console.warn("Unsafe user ID changed, logging out");
      Sentry.captureMessage("Unsafe user ID changed, logging out");
      return jumpToAuthFlow(url, `/${LOGOUT_PATH}`);
    }

    // Check if a token is present in the query params
    // If so, sign in with that token
    const token = searchParams.get("token");
    if (token) {
      console.debug("Logging in with token");
      try {
        const user = await signInWithCustomTokenFn(token);
        console.debug("Logged in with token", user);
        queryClient.setQueriesData(keyFactory.currentUser(), user.user);
        searchParams.delete("token");
        const continueURL = new URL(url.pathname, url.origin);
        continueURL.search = searchParams.toString();
        Sentry.captureMessage("Unsafe user ID changed, logging out");
        return redirect(continueURL.href);
      } catch (e) {
        if (e instanceof Error) {
          Sentry.captureException(e);
        }
        console.error(e);
      }
    }

    const hasSession = await queryClient
      .fetchQuery(hasSessionQuery)
      .catch(() => false);

    console.debug("Checking if user allowed to access this page");
    const isAuthenticated = await queryClient
      .fetchQuery(isAuthenticatedQuery)
      .then(isAuthenticatedQuery.select);
    if (!hasSession && !isAuthenticated) {
      console.debug("User not authenticated");
      Sentry.captureMessage("User not authenticated");
      return jumpToAuthFlow(url, `/${LOGIN_PATH}`);
    }

    console.debug("Checking if user has verified email");
    const hasVerifiedEmail = await queryClient
      .fetchQuery(hasVerifiedEmailQuery)
      .then(hasVerifiedEmailQuery.select);
    if (!hasSession && !hasVerifiedEmail)
      return jumpToAuthFlow(url, `/${VERIFY_EMAIL_PATH}`);

    console.debug("Checking if user has valid user data");
    const currentUserdata = await queryClient.fetchQuery(
      currentUserDataOrFalseQuery,
    );
    if (!isUserDataComplete(currentUserdata)) {
      console.debug("User has invalid user data", currentUserdata);
      return jumpToAuthFlow(url, `/${ASK_USER_DATA_PATH}`);
    }

    return null;
  };

import createProtector, {
  CreateProtectorConfig,
  PROTECTOR_CONFIG_DEFAULTS,
} from "@/pages/auth/createProtector";
import { ALL_PATIENTS_PATH } from "@/pages/patients/const";
import * as Sentry from "@sentry/react";
import { QueryClient } from "react-query";
import reportClient, { PatientIn } from "@/services/reports/client";
import { LoaderFunction, redirect } from "react-router";
import client, { PatientOut } from "@/services/reports/client";
import keyFactory from "@/services/reports/keyFactory";
import {
  createPatientsQuery,
  CreatePatientsQuery,
} from "@/services/reports/usePatients";
import parseURLParams from "./parseURLParams";

export type CreateLoaderConfig = {
  queryClient: QueryClient;
  createPatientsQuery: CreatePatientsQuery;
  createPatientFn: typeof client.v1.createPatientV1;
} & CreateProtectorConfig;

export const LOADER_CONFIG_DEFAULTS = {
  createPatientFn: ({ requestBody }: { requestBody: PatientIn }) =>
    reportClient.v1.createPatientV1({ requestBody }),
  createPatientsQuery,
  ...PROTECTOR_CONFIG_DEFAULTS,
};

export default (config: CreateLoaderConfig): LoaderFunction =>
  async ({ request, params, context }) => {
    const {
      createPatientsQuery,
      createPatientFn,
      queryClient,
      ...protectorConfig
    } = config;
    const url = new URL(request.url);
    Sentry.setContext(
      "searchParams",
      Object.fromEntries(url.searchParams.entries()),
    );
    // Check if user allowed to access this page
    console.debug("Checking if user allowed to access this page");
    const protector = createProtector({ ...protectorConfig, queryClient });
    const response = await protector({ request, params, context });
    if (response) {
      return response;
    }

    // Parse URL params
    console.debug("Parsing URL params");
    const {
      identifier,
      unparsed,
      patient: newPatient,
    } = parseURLParams(request);

    // Check if patient exists
    console.debug("Checking if patient exists");
    let patients: PatientOut[] = [];
    if (identifier) {
      patients = await queryClient.fetchQuery(
        createPatientsQuery({
          identifier: identifier.toToken(),
          limit: 1,
          offset: 0,
        }),
      );
    }

    let patient: PatientOut;
    if (patients.length > 0) {
      patient = patients[0];
      queryClient.setQueryData(keyFactory.patient(patient.id), patient);
    } else {
      if (!newPatient) {
        // Can't create patient without data
        throw new Error("No patient found and no patient data provided");
        // TODO: handle error
      }

      // Create patient if not exists
      console.debug("Creating patient", newPatient);
      try {
        patient = await createPatientFn({ requestBody: newPatient });
      } catch (e) {
        console.log(e);
        throw new Error("Error creating patient");
      }
      console.debug("Patient created");
      queryClient.setQueryData(keyFactory.patient(patient.id), patient);
    }
    // Redirect to report dispatch page
    return redirect(
      `/${ALL_PATIENTS_PATH}/${patient.id}/reports/dispatch?${unparsed.toString()}`,
    );
  };

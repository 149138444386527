import { Component, ErrorInfo, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import RenderBlockError from "./error-displays/RenderBlockError";

interface Props {
  children: ReactNode;
  blockId: string;
}

interface State {
  hasError: boolean;
}

class BlockErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error);
    console.error(
      "Uncaught error while rendering a building block.:",
      error,
      errorInfo,
    );
  }

  public render() {
    if (this.state.hasError) {
      return <RenderBlockError blockId={this.props.blockId} />;
    }

    return this.props.children;
  }
}

export default BlockErrorBoundary;
